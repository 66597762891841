/* tslint:disable:no-class readonly-keyword typedef*/
import LanguageKeys from "./en";

export class LanguageKeysAr extends LanguageKeys {
  //Subscriptions
  public usage = "الاستهلاك";
  public purchaseDate = "تاريخ الشراء";
  public CANCEL_SUBSCRIPTION_TITLE = "هل ترغب فى الغاء الاشتراك؟";
  //Packages
  PACKAGES_SUBTITLE = "الباقات";
  PERSONAL_TRAINING = "حصص التدريب الشخصي";
  public PURCHASE = "شراء";
  public EXPIRES_AFTER = "ينتهى بعد";
  //Dashboard
  public Testkey = "Test key";
  public FUTURE_APPOINTMENTS = "المواعيد القادمة";
  public SERVICE = "الخدمة";
  public VIEW_APPOINTMENTS = "عرض جميع المواعيد";
  public LATEST_PAYMENTS = "اخر المدفوعات";
  public POSTED_DATE = "تاريخ الدفع";
  public AMOUNT_PAID = "القيمة المدفوعة";
  public RECIEPT = "الفاتورة";
  public VIEW = "عرض";
  public VIEW_TRANSACTIONS = "عرض جميع المدفوعات";
  public CREDIT_CARDS = "البطاقات";
  public ADD_PAYMENT = "اضافة طريقة دفع";
  public NEXT_CLASS = "الحصة القادمة";
  public CLASS = "الحصة";
  public CHECK_DETAILS = "التفاصيل";
  public NO_CREDIT_DATA = "لا يوجد بطاقات";
  public NO_CLASS_DATA = "لا يوجد حصص قادمة";
  public No_Appointments = "لا يوجد مواعيد قادمة";
  public NO_TRAINERS_FOUND = "لا يوجد مدربين";
  public NO_ASSIGNED_PACKAGES = " لا يوجد ";

  public NO_APPT_DATA = "لا يوجد مواعيد قادمة";
  public NO_PAYMENT_DATA = "لا يوجد مدفوعات";
  public GYM_PRECAUTIONS = "احتياطات الصالة الرياضية";
  public BOOKING_CONFIRMED = "تم تأكيد الحجز";
  public NEED_HELP = "بحاجة الى مساعدتنا؟";
  public HELP_TEXT =
    "اتصل بنا في حالة احتياجك إلى أي دعم أو مواجهة أي مشكلة في خدمتنا";
  public THANKYOU_TEXT = "شكرا خالد لحجزك حصة";
  public LIST_ITEM1 = "عقم المعدات قبل وبعد الاستخدام";
  public LIST_ITEM2 = "حافظ على التباعد الاجتماعي";
  public LIST_ITEM3 = "اغسل وعقم يديك عند الدخول";
  public LIST_ITEM4 = "الحجز عبر الإنترنت مطلوب لجميع الحصص ";
  public LIST_ITEM5 = "من فضلك لا تدخل إذا كنت مريضا";
  public LIST_ITEM6 = "سعة محدودة ، ليس أكثر من 10 أشخاص لكل فصل";

  //Trainers
  public MOBILE = "الهاتف";
  public EMAIL = "الإيميل";
  public COUNTRY = "البلد";
  public BOOK_APT = "حجز موعد";
  public SELECT_PACKAGE = "اختر باقة";
  public SELECT = "اختر";
  public ADD_NOTES = "اكتب ملاحظاتك هنا";
  public SESSIONS = "حصص";
  public MANDATORY_FEILD = " الحقول ذات * اجبارية";
  public QUALIFICATIONS = "المؤهلات";
  //Assigned packages
  public packagePrice = "سعر الباقه";
  public startDate = " تاريخ البدء";
  public START_DATE = " تاريخ البدء";

  public expiryDate = "تاريخ الانتهاء";
  public inductionSessions = "حصص تعريفيه";
  public packageSessions = "Package Sessions";
  public sessions = "حصص";
  public inBalance = "متاح";
  public expiryIn = "ينتهى فى خلال";
  public days = "أيام";

  //Transactions History
  public postedDate = "تاريخ المعامله";
  public item = "العنصر";
  public amountPaid = "المبلغ المدفوع";
  public paymentLocation = "مكان الدفع";
  public ref = "المرجع";
  public receipt = "الفاتوره";
  public view = "مشاهده";
  public paymentDate = "تاريخ الدفع";
  public quantity = "الكميه";
  public discount = "الخصم";

  //Acc Settings page
  public userProfile = "المستخدم";
  public transactionsHistory = "تاريخ المعاملات";
  public assignedPackages = "الحزم المخصصه";
  public subscriptions = "الاشتراكات";

  //User profile
  public name = "الاسم";
  public email = "الايميل";
  public mobileNumber = "رقم المحمول";
  public dateOfBirth = "تاريخ الميلاد";
  public gender = "الجنس";
  public male = "ذكر";
  public female = "انثى";
  public save = "حفظ";
  public noTransactions = "لا يوجد معاملات ";

  //settings
  public settings = "اعدادات";
  public lang = "اللغه";
  public creditCards = "بطاقات الدفع";
  public en = "English";
  public ar = "العربيه";
  public currentPassword = "كلمه المرور الحاليه";
  public newPassword = "كلمه المرور الجديده";
  public confirm = "تأكيد";
  public success = "تم بنجاح";
  public success_msg = " لقد تم تحديث الاعدادات بنجاح!";
  public success_msg_package = "  لقد تم شراء باقتك  بنجاح!";
  public cancelled = "تم إلغاء الحصة التدريبة";
  public taken = "لقد تم الاشتراك في الحصة التدريبية مسبقاٌ";
  public advance_time_restriction = "بإمكانك الحجز قبل 23 ساعة فقط";
  public not_enough_credits = "لا يوجد رصيد كافي";

  //General
  public CONFIRM = "تأكيد";
  public CANCEL = "الغاء";
  public SUBMIT = "أرسال";
  //Login
  public LOGIN = "الدخول";
  public CONTINUE = "الإستمرار";
  public FORGOT_PASSWORD = "هل نسيت كلمة المرور ؟";
  //Navbar
  public LANGUAGE = "English";
  public DASHBOARD = "لوحة التحكم";
  public CLASSES = "الحصص";
  public TRAINERS = "المدربون";
  public APPOINTMENTS = "المواعيد";
  public PACKAGES = "الباقات";

  public GOOD_MORNING = "اهلا";
  //Classes
  public BOOK_NOW = "احجز الآن";
  public SEARCH = "بحث";
  public BRANCH = "فرع";
  public AVAILABLE = "متاح";
  public FULL = "ممتلئ";
  public SEARCH_PLACEHOLDER = "ابحث هنا...";
  public GROUP_SIZE = "حجم المجموعة";
  public LOCATION = "الموقع";
  public TRAINER = "المدرب";
  public DURATION = "المدة";
  public TIME = "الوقت";
  public BOOKING_CONFIRMATION = "تأكيد الحجز";
  public CONFIRM_BOOKING_MSG = "انت على وشك حجز حصة, هل انت متأكد ؟";
  //Account settings
  public ACCOUNT_SETTINGS = "اعدادات الحساب";
  public LOGOUT = "تسجيل الخروج ";
  public ERROR = "حدث خطأٌ ما";
  public SAR = "ر.س";
  public changeLocation = "تغيير الموقع";
  //Notification
  public NOTIFICATION_1 = "لديك";
  public NOTIFICATION_2 = " جلسات تعريفية مجانية تنتهي صلاحيتها خلال";
  public NOTIFICATION_3 = "اضغط هنا";
  public NOTIFICATION_4 = "لإنشاء موعد";
  //Appointments
  public CREATE_APPOINTMENT = "حجز موعد";
  DATE = "التاريخ";
  NOTES = "ملاحظات";
  public Amount = "المبلغ";
  public Invalid_Credentials = "خطأ في كلمة السر او اسم المستخدم";
  //payment
  public select_payment_method = "اختر طريقة الدفع";
  public proceed = "اكمل الشراء";

  //login
  public username = "اسم المستخدم";
  public password = "كلمة المرور";
  public RESET_PASSWORD_SENT =
    "تم إرسال بريد إلكتروني لك. يرجى اتباع التعليمات الموجودة في البريد الإلكتروني لإعادة تعيين كلمة المرور الخاصة بك";

  public SUCCESS = "تم بنجاح";
  public BACK_TO = "رجوع الي";
  public HOURS = "ساعات";
  public booking_available = "يمكنك الحجز بعد";
  public Gyms_Members = "EVOX Gyms Members";
  public Pay = "ادفع";
  public SELECT_LOCATION = "اختر الموقع";
  public SELECT_BRANCH = "اختر الموقع";
  public CONTACT_BANK = "يرجى الاتصال بالبنك الخاص بك";

  public "SUBSCRIBTION_CANCELLED" = "تم الغاء الأشتراك بنجاح";
  public "ACCESS_TILL" = " الدخول حتي";
  public CURRENT_PACKAGE = "الباقة الحالية";

  public MESSAGES = ({ count }: { count: number }) => {
    return count === 1 ? `${count} Nachricht` : `${count} Nachrichten`;
  };

  public NO_ACCOUNT = "ليس لديك حساب؟ سجل الان";
}

export default LanguageKeysAr;
