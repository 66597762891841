import {
  Card,
  Stack,
  Title,
  Group,
  Divider,
  Text,
  Anchor,
  Skeleton,
  MediaQuery,
} from "@mantine/core";
import { Dashboard } from "../../Styles";
import { DashboardNodata } from "./DashboardNodata";
import calendar from "./../../assets/calendar.png";
import React from "react";
import { useT, useLang } from "../../i18n";
import { format, add, eachDayOfInterval, addDays } from "date-fns";
import { Link } from "react-router-dom";

const FutureAppointments = (props: dashboardProps) => {
  const { classes } = Dashboard();
  const t = useT();

  return (
    <Card className={classes.card} radius="md" py={30} px={20}>
      <Skeleton
        radius="xl"
        width="70%"
        height="10%"
        sx={{
          "@media (max-width: 500px)": {
            paddingBottom: 40,
          },
        }}
        px={7}
        mb={10}
        pb={10}
        visible={props.fetchedStatus === 1 ? true : false}
      >
        <Stack align="flex-start" spacing={0}>
          <Title order={3} pb={0}>
            {
              //t("FUTURE_APPOINTMENTS")
              t("NEXT_CLASS")
            }
          </Title>

          <Divider color="#ce0f69" size="xl" mt={7} sx={{ width: 84 }} />
        </Stack>
      </Skeleton>
      {props.noData ? (
        <DashboardNodata img={calendar} msg={t("NO_APPT_DATA")} />
      ) : (
        <>
          <MediaQuery smallerThan={500} styles={{ display: "none" }}>
            <Group ml={7} align="flex-start" spacing={90}>
              <Group spacing={20}>
                <Stack spacing={10} align="flex-start">
                  <Skeleton
                    radius="xl"
                    visible={props.fetchedStatus === 1 ? true : false}
                  >
                    <Text size={11}> {t("DATE")}</Text>
                  </Skeleton>

                  <Skeleton
                    radius="xl"
                    visible={props.fetchedStatus === 1 ? true : false}
                  >
                    <Text size={11}>{t("LOCATION")}</Text>
                  </Skeleton>
                  <Skeleton
                    radius="xl"
                    visible={props.fetchedStatus === 1 ? true : false}
                  >
                    <Text size={11}>{t("DURATION")}</Text>
                  </Skeleton>
                </Stack>

                <Stack spacing={10}>
                  <Skeleton
                    radius="xl"
                    visible={props.fetchedStatus === 1 ? true : false}
                  >
                    <Text size={11} color="default.8">
                      {format(
                        new Date(props.fetchedData.occurs_at),
                        "dd/MM/yyyy @ hh:mm aa"
                      )}
                    </Text>
                  </Skeleton>
                  <Skeleton
                    radius="xl"
                    visible={props.fetchedStatus === 1 ? true : false}
                  >
                    <Text size={11} color="default.8">
                      {props.fetchedData.location_name}
                    </Text>
                  </Skeleton>
                  <Skeleton
                    radius="xl"
                    visible={props.fetchedStatus === 1 ? true : false}
                  >
                    <Text size={11} color="default.8">
                      {props.fetchedData.duration_in_hours
                        ? `${props.fetchedData.duration_in_hours} Hrs`
                        : ""}

                      {props.fetchedData.duration_in_minutes
                        ? `${props.fetchedData.duration_in_minutes} Mins`
                        : ""}
                    </Text>
                  </Skeleton>
                </Stack>
              </Group>

              <Group spacing={40}>
                <Stack spacing={10}>
                  <Skeleton
                    radius="xl"
                    visible={props.fetchedStatus === 1 ? true : false}
                  >
                    <Text size={11}>{t("SERVICE")}</Text>
                  </Skeleton>
                  <Skeleton
                    radius="xl"
                    visible={props.fetchedStatus === 1 ? true : false}
                  >
                    <Text size={11}>{t("TRAINER")}</Text>
                  </Skeleton>
                </Stack>
                <Stack spacing={10}>
                  <Skeleton
                    radius="xl"
                    visible={props.fetchedStatus === 1 ? true : false}
                  >
                    <Text size={11} color="default.8">
                      {props.fetchedData.service_title}
                    </Text>
                  </Skeleton>
                  <Skeleton
                    radius="xl"
                    visible={props.fetchedStatus === 1 ? true : false}
                  >
                    <Text size={11} color="default.8">
                      {props.fetchedData.trainer_name}
                    </Text>
                  </Skeleton>
                </Stack>
              </Group>
            </Group>
          </MediaQuery>
          <MediaQuery largerThan={500} styles={{ display: "none" }}>
            <Group ml={7} align="flex-start" spacing={15}>
              <Group spacing={10}>
                <Stack spacing={5} align="flex-start">
                  <Skeleton
                    radius="xl"
                    visible={props.fetchedStatus === 1 ? true : false}
                  >
                    <Text size={11}> {t("DATE")}</Text>
                  </Skeleton>

                  <Skeleton
                    radius="xl"
                    visible={props.fetchedStatus === 1 ? true : false}
                  >
                    <Text size={11}>{t("LOCATION")}</Text>
                  </Skeleton>
                  <Skeleton
                    radius="xl"
                    visible={props.fetchedStatus === 1 ? true : false}
                  >
                    <Text size={11}>{t("DURATION")}</Text>
                  </Skeleton>
                </Stack>

                <Stack spacing={5}>
                  <Skeleton
                    radius="xl"
                    visible={props.fetchedStatus === 1 ? true : false}
                  >
                    <Text size={10} color="default.8">
                      {format(
                        new Date(props.fetchedData.occurs_at),
                        "dd/MM/yyyy @ hh:mm aa"
                      )}
                    </Text>
                  </Skeleton>
                  <Skeleton
                    radius="xl"
                    visible={props.fetchedStatus === 1 ? true : false}
                  >
                    <Text size={10} color="default.8">
                      {props.fetchedData.location_name}
                    </Text>
                  </Skeleton>
                  <Skeleton
                    radius="xl"
                    visible={props.fetchedStatus === 1 ? true : false}
                  >
                    <Text size={10} color="default.8">
                      {props.fetchedData.duration_in_hours
                        ? `${props.fetchedData.duration_in_hours} Hrs`
                        : ""}

                      {props.fetchedData.duration_in_minutes
                        ? `${props.fetchedData.duration_in_minutes} Mins`
                        : ""}
                    </Text>
                  </Skeleton>
                </Stack>
              </Group>

              <Group spacing={10}>
                <Stack spacing={5}>
                  <Skeleton
                    radius="xl"
                    visible={props.fetchedStatus === 1 ? true : false}
                  >
                    <Text size={10}>{t("SERVICE")}</Text>
                  </Skeleton>
                  <Skeleton
                    radius="xl"
                    visible={props.fetchedStatus === 1 ? true : false}
                  >
                    <Text size={10}>{t("TRAINER")}</Text>
                  </Skeleton>
                </Stack>
                <Stack spacing={5}>
                  <Skeleton
                    radius="xl"
                    visible={props.fetchedStatus === 1 ? true : false}
                  >
                    <Text size={10} color="default.8">
                      {props.fetchedData.service_title}
                    </Text>
                  </Skeleton>
                  <Skeleton
                    radius="xl"
                    visible={props.fetchedStatus === 1 ? true : false}
                  >
                    <Text size={10} color="default.8">
                      {props.fetchedData.trainer_name}
                    </Text>
                  </Skeleton>
                </Stack>
              </Group>
            </Group>
          </MediaQuery>
          <Anchor
            color="default.2"
            size={10}
            underline
            mb={18}
            sx={{ alignSelf: "flex-end" }}
            component={Link}
            to="/appointments"
          >
            {t("VIEW_APPOINTMENTS")}
          </Anchor>{" "}
        </>
      )}
    </Card>
  );
};

export default FutureAppointments;

type dashboardProps = {
  fetchedData: any;
  fetchedStatus: any;
  noData: Boolean;
  // input: string;
};
