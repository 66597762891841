import React, { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import Loader from "../Components/Loader";

import { Title, Divider, Button } from "@mantine/core";
import { Link } from "react-router-dom";
import { IconArrowNarrowRight, IconCirclePlus } from "@tabler/icons";
import { T, useT, useSetLang, useLang } from "../i18n/index";
import { loginStyles, PackagesStyle } from "../Styles";
import { getAppointments } from "../endpoints/api";
import { useQuery, useIsFetching } from "react-query";
import { format, add, eachDayOfInterval, addDays } from "date-fns";
import { useGetProfile } from "../api/auth";
import NoData from "../Components/NoData";
import Calendar from "./../assets/calendar.png";

import { Group, Stack } from "@mantine/core";

function Appointments() {
  const lang = useLang();
  const { classes } = loginStyles();
  const { classes: packgeClasses } = PackagesStyle();
  const t = useT();
  const [btnLoading, setBtnLoading] = useState(false);

  const { data: user, isLoading: isUserLoading } = useGetProfile();

  const userId = user?.id;

  const { data, status, isLoading, isError, isFetched, refetch } = useQuery(
    ["appointments", userId],
    () =>
      getAppointments(
        format(new Date(), "yyyy-MM-dd"),
        format(new Date(), "yyy-MM-dd"),
        userId
      ),
    {
      enabled: !!userId,
    }
  );

  return (
    <Stack className={packgeClasses.packagesScroll} align="flex-start">
      <Stack className={""} mb={10} justify="flex-start" spacing={0}>
        <div className="d-flex flex-column justify-content-start">
          <Title order={2} align="left">
            {t("APPOINTMENTS")}
          </Title>
          <Divider color="#ce0f69" size="xl" mt={7} sx={{ width: 84 }} />
        </div>
      </Stack>

      {/*  <Link className={classes.button2} to="/classes">
        <span className="font-size-12">{t("CREATE_APPOINTMENT")}</span>
      </Link> */}
      <div className="appointments-wrapper d-flex justify-content-start flex-wrap">
        {isLoading && <Loader />}

        {!isLoading &&
          data &&
          data.occurrences &&
          data.occurrences?.length !== 0 &&
          data.occurrences.map((appointment: any) => (
            <div className="appointments-item bg-white shadow">
              <div className="data-row d-flex flex-row">
                <div className="item-label text-capitalize">{t("DATE")}</div>
                <div className="item-data text-capitalize">
                  {format(
                    new Date(appointment.occurs_at),
                    "dd/MM/yyyy @ hh:mm aa"
                  )}
                </div>
              </div>
              <div className="data-row d-flex flex-row">
                <div className="item-label text-capitalize">
                  {t("LOCATION")}
                </div>
                <div className="item-data text-capitalize">
                  {appointment?.location_name}
                </div>
              </div>
              <div className="data-row d-flex flex-row">
                <div className="item-label text-capitalize">{t("TRAINER")}</div>
                <div className="item-data text-capitalize">
                  {appointment.trainer_name}
                </div>
              </div>
              <div className="data-row d-flex flex-row">
                <div className="item-label text-capitalize">
                  {t("DURATION")}
                </div>
                <div className="item-data text-capitalize">
                  {appointment.duration_in_hours
                    ? `${appointment.duration_in_hours} Hrs`
                    : ""}

                  {appointment.duration_in_minutes
                    ? `${appointment.duration_in_minutes} Mins`
                    : ""}
                </div>
              </div>
              <div className="data-row d-flex flex-row">
                <div className="item-label text-capitalize">{t("SERVICE")}</div>
                <div className="item-data text-capitalize">
                  {appointment.service_title}
                </div>
              </div>
              <div className="data-row d-flex flex-row">
                <div className="item-label text-capitalize">{t("NOTES")}</div>
                <div className="item-data text-capitalize">
                  {appointment.notes ? "" : "-"}
                </div>
              </div>
            </div>
          ))}
      </div>
      {!isLoading &&
        data &&
        data.occurrences &&
        data.occurrences?.length == 0 && (
          <NoData img={Calendar} msg={t("No_Appointments")} />
        )}
    </Stack>
  );
}

export default Appointments;
