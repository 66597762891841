import { useState } from "react";
import {
  Card,
  Group,
  Text,
  Button,
  Modal,
  Stack,
  Title,
  Divider,
  Radio,
} from "@mantine/core";
import { useQuery, useIsFetching } from "react-query";
import { UnstyledButton, Checkbox, createStyles } from "@mantine/core";
import { useUncontrolled } from "@mantine/hooks";
import { PackagesStyle } from "../../Styles";
import { SegmentedControl } from "@mantine/core";
import { useGetProfile } from "../../api/auth";
import { T, useT } from "../../i18n";
const useStyles = createStyles((theme) => ({
  button: {
    display: "flex",
    width: "100%",
    border: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[8] : theme.colors.gray[3]
    }`,
    borderRadius: theme.radius.sm,
    padding: theme.spacing.lg,
    backgroundColor:'#ce0f69',
    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[9]
          : theme.colors.gray[0],
    },
  },
}));

const PackagesCard = ({ bundle, setOpened, setBundle, type }: any) => {
  const { classes } = PackagesStyle();

  const { cx } = useStyles();
  const checked = false;
  const defaultChecked = false;
  const t = useT();
  const cost = bundle?.cost?.amount && bundle?.cost?.amount / 100;
  const custom_price =
    bundle?.custom_price?.amount && bundle?.custom_price?.amount / 100;
  const onChange = () => {};

  if (!bundle) return null;
  return (
    <Card className={classes.card}>
      <Text size={18}>{bundle?.name}</Text>
      <Group>
        <Text sx={{ width: 180 }} size={14}>
          {bundle?.description}
        </Text>{" "}
        <Text weight="bolder" size={38} color="#343741">
          {cost}
          {custom_price}
          <Text mt={30} weight="normal" size={14}>
            {(cost || custom_price) && t("SAR")}
          </Text>
        </Text>
      </Group>
      {/* <Group>
        <Text size={13}>{t('EXPIRES_AFTER')}</Text>
        <Text size={13} color="default.8">
          {bundle?.name}
        </Text>
      </Group> */}
      <Button
        onClick={() => {
          bundle.type = type;
          setBundle(bundle);
          setOpened(true);
        }}
        sx={{ alignSelf: "center",backgroundColor:'#ce0f69'}}
        mt={10}
      >
        {t("PURCHASE")}
      </Button>
    </Card>
  );
};

export default PackagesCard;
