export class LanguageKeys {
  //Subscriptions
  public usage = "Usage";
  public purchaseDate = "Purchase Date";
  public CANCEL_SUBSCRIPTION_TITLE =
    "Are you sure you want to cancel this subscription?";
  //Dashboard
  public Testkey = "Test key";
  public FUTURE_APPOINTMENTS = "Future Appointments";
  public SERVICE = "Service";
  public VIEW_APPOINTMENTS = "View All Appointments";
  public LATEST_PAYMENTS = "Latest Payments";
  public POSTED_DATE = "Posted Date";
  public AMOUNT_PAID = "Amount Paid";
  public RECIEPT = "Reciept";
  public VIEW = "VIEW";
  public VIEW_TRANSACTIONS = "View All Transactions";
  public CREDIT_CARDS = "Credit Cards";
  public ADD_PAYMENT = "Add Payment Method";
  public NEXT_CLASS = "Next Class";
  public CLASS = "Class";
  public CHECK_DETAILS = "Check Details";
  public NO_CREDIT_DATA = "No Credit Cards Found";
  public NO_CLASS_DATA = "No Classes Found";
  public NO_TRAINERS_FOUND = "No trainers Found";
  public NO_ASSIGNED_PACKAGES = "No Assigned Packages";
  public cancelled = "Session been canceled";
  public NO_APPT_DATA = "No Appointments Found";
  public NO_PAYMENT_DATA = "No Payments Found";
  public GYM_PRECAUTIONS = "Gym Precautions";
  public BOOKING_CONFIRMED = "Booking Confirmed";
  public NEED_HELP = "Need Our Help?";
  public HELP_TEXT =
    "Call us in case you need any support or face any issue at our service";
  public THANKYOU_TEXT = `Thanks Khaled for booking Bootcamp class`;
  public LIST_ITEM1 = "Sanitize equipment before and after use";
  public LIST_ITEM2 = "Maintain social distancing";
  public LIST_ITEM3 = "Wash & Sanitize your hands upon entry";
  public LIST_ITEM4 = "Online Booking required for all classes ";
  public LIST_ITEM5 = "Please don't enter if you are sick";
  public LIST_ITEM6 = "Limited Capacity , No more than 10 people per class";
  public No_Appointments = "No Appointments";
  public booking_available = "you can start booking in";
  public taken = "You have already joined the training session";
  public advance_time_restriction = "Booking only available 23h ahead";
  public not_enough_credits = "Not enough credits";

  //Trainers
  public MOBILE = "Mobile";
  public EMAIL = "E-mail";
  public COUNTRY = "Country";
  public BOOK_APT = "Book Appointment";
  public SELECT_PACKAGE = "Select Package";
  public SELECT = "Select";
  public ADD_NOTES = "Write Down Your Notes";
  public SESSIONS = "Sessions";
  public MANDATORY_FEILD = "Fields with * sign are mandatory to be filled";
  public QUALIFICATIONS = "qualifications";

  //Assigned packages
  public packagePrice = "Package Price";
  public startDate = "Start Date";
  public expiryDate = "Expiry Date";
  public inductionSessions = "Inductions Sessions";
  public packageSessions = "Package Sessions";
  public sessions = "sessions";
  public inBalance = "In Balance";
  public expiryIn = "Expiry In";
  public days = "days";

  // Packages
  PACKAGES_SUBTITLE = "Packages";
  PERSONAL_TRAINING = "Personal Training Sessions";
  public PURCHASE = "Purchase";
  public EXPIRES_AFTER = "Expires after";
  //Transactions History
  public postedDate = "Posted Date";
  public item = "Item";
  public amountPaid = "Amount Paid";
  public paymentLocation = "Payment Location";
  public ref = "Ref";
  public receipt = "Receipt";
  public view = "VIEW";
  public paymentDate = "Payment Date";
  public quantity = "Quantity";
  public discount = "Discount";

  //Acc Settings page
  public userProfile = "User Profile";
  public transactionsHistory = "Transactions History";
  public assignedPackages = "Assigned Packages";
  public subscriptions = "Subscriptions";

  //User profile
  public name = "Name";
  public email = "E-mail";
  public mobileNumber = "Mobile Number";
  public dateOfBirth = "Date of Birth";
  public gender = "Gender";
  public male = "Male";
  public female = "Female";
  public save = "Save";
  public noTransactions = "no transactions yet";

  //settings
  public settings = "Settings";
  public lang = "Language";
  public creditCards = "Credit Cards";
  public en = "English";
  public ar = "Arabic";
  public currentPassword = "Current Password";
  public newPassword = "New Password";
  public confirm = "Confirm";
  public WELCOME_HEADLINE = "Welcome";

  //General
  public CONFIRM = "Confirm";
  public CANCEL = "Cancel";
  //Login
  public LOGIN = "Login";
  public CONTINUE = "Continue";
  public FORGOT_PASSWORD = "Forgot Your Password ?";
  //Navbar
  public LANGUAGE = "العربية";
  public DASHBOARD = "Dashboard";
  public CLASSES = "Classes";
  public TRAINERS = "Trainers";
  public APPOINTMENTS = "Appointments";
  public PACKAGES = "PT & Packages";
  public GOOD_MORNING = "Welcome";
  //Classes
  public BOOK_NOW = "Book Now";
  public SEARCH = "Search";
  public BRANCH = "Branch";
  public AVAILABLE = "AVAILABLE";
  public FULL = "FULL";
  public SEARCH_PLACEHOLDER = "Write Your Search Here";
  public GROUP_SIZE = "Group Size";
  public LOCATION = "Location";
  public TRAINER = "Trainer";
  public DURATION = "Duration";
  public TIME = "Time";
  public BOOKING_CONFIRMATION = "Booking Confirmation";
  public CONFIRM_BOOKING_MSG = "You are about to book a class, are you sure ?";
  //Account settings
  public ACCOUNT_SETTINGS = "Account Settings ";
  public LOGOUT = "Logout";
  public ERROR = "ERROR";
  public SUBMIT = "SUBMIT";
  public success = "success";
  public success_msg = " Your changes has been applied successfully";
  public success_msg_package = " Your package has been purchased successfully";
  public changeLocation = "Change Location";

  //Notification
  public NOTIFICATION_1 = "you have";
  public NOTIFICATION_2 = " free induction sessions that will expire";
  public NOTIFICATION_3 = "Click here";
  public NOTIFICATION_4 = "to create an appointment";
  //Appointments
  public CREATE_APPOINTMENT = "Create Appointment";
  public DATE = "Date";
  public NOTES = "Notes";
  public BOOKED = "Booked";
  public SAR = "SAR";
  public Amount = "Amount";
  public Invalid_Credentials = "Invalid Credentials";
  public RESET_PASSWORD_SENT =
    "An email has been sent to you. Please follow the instructions on the email to reset your password";
  public SUCCESS = "Success";
  //payment
  public select_payment_method = "Select Payment method";
  public proceed = "Proceed";

  //login
  public username = "Username";
  public password = "Password";
  public BACK_TO = "Back to";
  public HOURS = "Hours";
  public Gyms_Members = "EVOX Gyms Members";
  public Pay = "Pay";
  public SELECT_LOCATION = "Please select your location";

  public SELECT_BRANCH = "Select Branch Location";
  public CONTACT_BANK = "Please contact your bank";
  public "SUBSCRIBTION_CANCELLED" = "Subscription Cancelled Successfully";
  public "ACCESS_TILL" = "Access till";
  public CURRENT_PACKAGE = "CURRENT PACKAGES";
  public START_DATE = "START DATE";

  public MESSAGES = ({ count }: { count: number }) => {
    return count === 1 ? `${count} Message` : `${count} Messages`;
  };

  public NO_ACCOUNT = "Don't have an account ? Sign up";
}

export type LangProps = keyof LanguageKeys;
export default LanguageKeys;
