import {
  Center,
  Group,
  Stack,
  Text,
  TextInput,
  Title,
  Divider,
  Indicator,
} from "@mantine/core";
import ClassCard from "../Components/ClassCard";
import { classCardStyle } from "../Styles";
import { T, useT, useSetLang, useLang } from "../i18n/index";

import Calendar from "../Components/Calendar";
import { useState, useMemo, useEffect } from "react";
import { useQuery, useIsFetching } from "react-query";
import { format, add, eachDayOfInterval, addDays } from "date-fns";
import { getClasses, getLocation } from "../endpoints/api";
import NoData from "../Components/NoData";
import Dumbbell from "./../assets/dumbbell.png";
import Loader from "../Components/Loader";
import { useGetProfile } from "../api/auth";
import { useAuth } from "./../utils/auth";
import {
  getPackages,
  getPTPackages,
  getUserPackages,
  getRenwalPackages,
  getFounderRenwalPackages,
  getUserProfile,
} from "../endpoints/api";

function Classes() {
  const isFetching = useIsFetching();
  const lang = useLang();
  const t = useT();
  const auth = useAuth();
  const setLang = useSetLang();
  const { classes } = classCardStyle();

  const {
    data: user,
    isLoading: isUserLoading,
    isFetched: isUserFetched,
  } = useGetProfile();

  const userLocationId = user?.home_location_id;

  const { data, status, isLoading, isError, isFetched, refetch } = useQuery(
    ["class2"],
    () => {
      return getClasses(
        format(getSelectedDays[activeIndex], "yyyy-MM-dd"),
        format(getSelectedDays[activeIndex], "yyy-MM-dd"),
        userLocationId
      );
    },
    { keepPreviousData: true, enabled: !!userLocationId }
  );

  const {
    data: userPackages,
    isLoading: isUserPackagesLoading,
    isFetched: isUserPackagesFetched,
  } = useQuery(["userPackages"], () => getUserPackages(), {});

  const {
    data: location,
    status: locationStatus,
    isLoading: isLocationLoading,
    isError: isLocationError,
    isFetched: isLocationFetched,
    refetch: locationRefetch,
  } = useQuery(
    ["userLocation"],
    () => {
      return getLocation(userLocationId);
    },
    {
      keepPreviousData: true,
      enabled: !!userLocationId,
    }
  );

  const [count, setCount] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    refetch();
  }, [count, activeIndex]);

  const initialDate = addDays(new Date(), count * 7);

  function getRequestedDays(startDate = initialDate) {
    const aWeekFromNow = addDays(startDate, 6);
    const thisWeek = eachDayOfInterval({ start: startDate, end: aWeekFromNow });

    return thisWeek;
  }
  const getSelectedDays = useMemo(() => getRequestedDays(initialDate), [count]);

  const canBookClass = useMemo(
    () =>
      userPackages?.client_service_packages != undefined &&
      userPackages?.client_service_packages?.length != 0,
    [userPackages]
  );

  const [inputText, setInputText] = useState("");
  let inputHandler = (e: any) => {
    //convert input text to lower case
    var lowerCase = e.target.value.toLowerCase();
    setInputText(lowerCase);
  };

  return (
    <div>
      {isFetched && (
        <Calendar
          fetchedData={data?.occurrences}
          loadingStatus={status}
          fetchedStatus={isFetched}
          getSelectedDays={getSelectedDays}
          setCount={setCount}
          count={count}
          setActiveIndex={setActiveIndex}
          activeIndex={activeIndex}
          lang={lang}
        />
      )}

      <Group position="apart" className={classes.branch} my={20}>
        <Text size="lg">
          {isLocationFetched && location?.location?.title} {t("BRANCH")} -{" "}
          {auth?.user?.club_name}
        </Text>
        <Group>
          <Text size="xs" color="#1D1D1D">
            {t("SEARCH")}
          </Text>
          <TextInput
            radius={5}
            variant="unstyled"
            style={{
              borderRadius: "5px",
              fontSize: "12px",
              paddingBottom: "0px!important",
              background: "#fff",
              width: "300px",
            }}
            onChange={inputHandler}
            placeholder={t("SEARCH_PLACEHOLDER")}
          />
        </Group>
      </Group>
      <Stack
        className={classes.title}
        mx={100}
        justify="flex-start"
        spacing={0}
      >
        <div className="d-flex flex-column justify-content-start">
          <Title order={2} align="left">
            {t("CLASSES")}
          </Title>
          <Divider color="#ce0f69" size="xl" mt={7} sx={{ width: 84 }} />
        </div>
      </Stack>
      {isFetched &&
        !isLoading &&
        data !== undefined &&
        data.occurrences?.length !== 0 && (
          <ClassCard
            fetchedData={data.occurrences}
            fetchedStatus={isFetching}
            input={inputText}
            canBookClass={canBookClass}
          />
        )}
      {isFetched &&
        !isLoading &&
        data !== undefined &&
        data.occurrences?.length === 0 && (
          <NoData img={Dumbbell} msg={t("NO_CLASS_DATA")} />
        )}
      {isLoading && <Loader />}

      <Group className="mt-5 mb-4" position="right" spacing={20} mr={20}>
        <Text size="xs">
          <Indicator color="#16DF08" size={17} offset={8} inline mr={20}>
            {" "}
          </Indicator>{" "}
          {t("AVAILABLE")}
        </Text>

        <Text size="xs">
          <Indicator
            color="#DF1E08"
            size={17}
            offset={8}
            inline
            mr={20}
            ml={25}
          >
            {" "}
          </Indicator>{" "}
          {t("FULL")}
        </Text>
      </Group>
    </div>
  );
}

export default Classes;
