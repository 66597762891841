import React from "react";
import { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Helmet } from "react-helmet";
import Router from "./routes/index";
import { T, useT, useSetLang, useLang } from "./i18n/index";
import { datadogRum } from "@datadog/browser-rum";

function App() {
  const t = useT();
  const setLang = useSetLang();
  const lang = useLang();

  datadogRum.init({
    applicationId: "6af1cd32-f0bd-4852-b94a-04becf5dfaf2",
    clientToken: "pubc47ab3e8dc31ec9f18ed42deb75e8891",
    site: "datadoghq.com",
    service: "activ-gyms-members",
    env: "production",
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
  });

  return (
    <>
      <Helmet>
        <title>{t("Gyms_Members")}</title>
      </Helmet>
      <BrowserRouter>
        <Router />
      </BrowserRouter>
    </>
  );
}

export default App;
